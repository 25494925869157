import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import "antd/dist/antd.css";
import { Switch } from "antd";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

const nachtyp_status ={
  Expired:"E",
  Physical:"P"
}
class NACH_Deactivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrOpen: false,
      isView: false,
      viewFile: "",
      api_error_message: "",
      loan_list: [],
      pull_id: 0,
      flag: 0,
      remarks: "",
      Pull_check: [],
      monthdata: "",
      search_text: "",
      search_Deactivatedtext:"",
      og_data: [],
      inactivenachcases: [],
      isSpin: false,
      isCreateuser: false,
      collection_ac: "",
      partnerpayment: [],
      pgdata: [],
      PhisicalSuccess: "",
      nachtyp: "",
      iSduplicateNACH: false,
      isActive: 1,
      orderSuccess: [],
      isOrderid: false,
      NACH_form_upload: "",
      ISNACH_form_upload: false,
      deac_bank_id: 0,
      deac_hh_id: 0,
    };
  }
  // componentDidMount() {
  //   this.fetchLoanList();
  // }
  // componentDidUpdate() {
  //   const $ = window.$;
  //   $(document).ready(function () {
  //     setTimeout(() => {
  //       $("#findash").DataTable();
  //       $("#findash_sanc").DataTable();
  //     }, 2000);
  //   });
  // }

  fetchLoanList() {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    customFetch(process.env.REACT_APP_URL + "/get/loan/list/nach/deactivaiton?household_number="+this.state.search_text, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        // console.log(json["loan_list"]);
        this.setState(
          {
            loan_list: json["loan_list"],
            og_data: json["loan_list"],
            isSpin:false,
          },
          function() {
            const $ = window.$;
            $("#findash").DataTable();
          }
        );
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  handelChange = (checked) => {
    console.log(checked);
    this.setState({
      pull_flag: checked ? 1 : 0,
    });
  };
  chkall = (e) => {
    const $ = window.$;
    if (e.target.checked) {
      $(".sub_chk").prop("checked", true);
    } else {
      $(".sub_chk").prop("checked", false);
    }
    var selected = [];
    $(".sub_chk:checkbox:checked").each(function(index, data) {
      selected.push(data.value);
    });
    this.setState({
      Pull_check: selected,
    });
  };
  handleCheckboxChange = (event) => {
    let newArray = [...this.state.Pull_check, event.target.value];
    if (this.state.Pull_check.includes(event.target.value)) {
      newArray = newArray.filter((day) => day !== event.target.value);
    }
    this.setState({
      Pull_check: newArray,
    });
  };

  setBankIDForDeactivation = (e, e1) => {
    this.state.deac_bank_id = e;
    this.state.deac_hh_id = e1;
  };

  deactivateNACH = (e) => {
    if (this.state.remarks == "") {
      this.setState({
        isErrOpen: true,
        api_error_message: "Remark Cannot be Blank",
      });
    } else {
      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      const uid = localStorage.getItem("in_userid");
      // var ldi = "";
      // for (var i = 0; i < this.state.loan_list.length; i++) {
      //   if (this.state.loan_list[i].id == e) {
      //     ldi = this.state.loan_list[i].loan_documentation_id;
      //   }
      // }

      const formdata = new FormData();
      formdata.append("userid", uid);
      formdata.append("bank_id", this.state.deac_bank_id);
      formdata.append("hh_id", this.state.deac_hh_id);
      formdata.append("remark", this.state.remarks);

      customFetch(process.env.REACT_APP_URL + "/set/loan/nach/deactivaiton", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json);
          if (json["status"] == 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: json["message"],
            });
          } else {
            window.location.reload(true);
          }
        }).catch((error) => console.log("error", error));;
    }

    //
  };

  handleRemarks = (event) => {
    // console.log(event);
    this.state.remarks = event.target.value;
  };

  approvalRejection = (event) => {
    if (this.state.remarks == "") {
      this.setState({
        isErrOpen: true,
        api_error_message: "Remark Cannot be Blank",
      });
    } else {
      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      const uid = localStorage.getItem("in_userid");
      // var ldi = "";
      // for (var i = 0; i < this.state.loan_list.length; i++) {
      //   if (this.state.loan_list[i].id == this.state.pull_id) {
      //     ldi = this.state.loan_list[i].loan_documentation_id;
      //   }
      // }
      const formdata = new FormData();
      formdata.append("userid", uid);
      formdata.append("bank_id", this.state.deac_bank_id);
      formdata.append("flag", this.state.flag);
      formdata.append("hh_id", this.state.deac_hh_id);
      formdata.append("remarks", this.state.remarks);

      customFetch(process.env.REACT_APP_URL + "/approve/reject/nach/deactivaiton", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json);
          if (json["status"] == 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: json["message"],
            });
          } else {
            this.setState({
              isErrOpen: true,
              api_error_message: json["message"],
            });
            window.location.reload(false);
          }
        }).catch((error) => console.log("error", error));;
    }
  };

  approveReject = (id, hh_id, flag) => {
    //
    // console.log(id + " " + flag)
    this.state.deac_bank_id = id;
    this.state.flag = flag;
    this.state.deac_hh_id = hh_id;

    //

    //
  };

  add_bouncecharge = (e) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    for (let index = 0; index < this.state.Pull_check.length; index++) {
      console.log(this.state.Pull_check[index]);
      if (uid) {
        const formdata = new FormData();
        formdata.append("userid", uid);
        formdata.append("nach_process_id", this.state.Pull_check[index]);
        customFetch(process.env.REACT_APP_URL + "/set/loan/charges", {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.setState({
          isErrOpen: true,
          api_error_message: "Please Contact Support",
        });
      }
    }
    window.location.reload(false);
  };
  remove_bouncecharge = (e) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    for (let index = 0; index < this.state.Pull_check.length; index++) {
      console.log(this.state.Pull_check[index]);
      if (uid) {
        const formdata = new FormData();
        formdata.append("userid", uid);
        formdata.append("nach_process_id", this.state.Pull_check[index]);
        customFetch(process.env.REACT_APP_URL + "/remove/loan/charges", {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        this.setState({
          isErrOpen: true,
          api_error_message: "Please Contact Support",
        });
      }
    }
    window.location.reload(false);
  };
  updatemonthdata = (e) => {
    this.setState({ monthdata: e.target.value }, this.fetchfailpull());
  };
  handleTabClick = (event, tab) => {
    if (tab === 1) {
      console.log("Tab 1");
      //this.fetchDetails();
      this.setState({ isActive: 1 });
    }
    if (tab === 2) {
      console.log("Tab 2");
      //this.inactivenachcases();
      this.setState({ isActive: 2 });
    }
  };
  // NACH Register popup
  createuserpopup(
    hhid,
    acno,
    ifsc,
    hhno,
    hhname,
    hhactype,
    loan_id,
    bank_name,
    loan_number,
    partner_id
  ) {
    this.setState({ isSpin: true, prevloans: [] });
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    // /* Fetch Partner List Start */
    customFetch(process.env.REACT_APP_URL + "/get/partner/payment/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          partnerpayment: json,
        });
      })
      .catch((error) => console.log("error", error));
    /* Fetch Partner List End */
    /* Fetch All Loans List End */
    // const formdata = new FormData();
    // // formdata.append("in_household_id", "46");
    // formdata.append("household_number", hhno);
    // formdata.append("includeClosedLoansFlag", 1);

    customFetch(
      process.env.REACT_APP_URL +
        "/get/householdlist?household_number=" +
        hhno +
        "&includeClosedLoansFlag=1",
      {
        method: "GET",
        headers: { "api-token": api_token },
        //body: formdata,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        //console.log("loan_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          loan_data: json,
        });
        json.forEach((element) => {
          if (this.state.prevloans.indexOf(element) == -1) {
            if (element.nach_partner_id && element.nach_token_id) {
              this.state.prevloans.push({
                loan_id: element.loan_id,
                map_id: element.nach_partner_id,
                account_number: element.account_number,
                nach_token_id: element.nach_token_id,
                nach_type: element.nach_type,
                prev_loan_documentation_id: element.loan_number,
              });
            }
          }
        });
      })
      .catch((error) => console.log("error", error));
    /* Fetch All Loans List End */
    this.setState({
      isCreateuser: true,
      isRegisterdisable: false,
      nachtyp: "",
      productdata: "",
      collection_ac: "",
      hhid: hhid,
      acno: acno,
      ifsc: ifsc,
      hhno: hhno,
      name_as_per_account: hhname,
      account_type_name: hhactype,
      loan_id: loan_id,
      bank_name: bank_name,
      loan_documentation_id: loan_number,
      productdata: partner_id,
      isSpin: false,
    });
    var z = 0;
    setInterval(() => {
      if (z < 1) {
        this.getpg();
        z++;
      }
    }, 1000);
  }
  // filter list of payment gateway
  getpg() {
    console.log("e.target.name", this.state.productdata);
    // const name = e.target.name;
    // let value = e.target.value;
    // this.setState({ [name]: value });
    this.state.sort_data = [];

    for (let i = 0; i < this.state.partnerpayment.length; i++) {
      if (
        this.state.partnerpayment[i].partner_id == this.state.productdata //parseInt(e.target.value)
      ) {
        //this.state.sort_data.push(this.state.partnerpayment[i].collection_ac);
        this.setState({
          pgdata: this.state.partnerpayment[i].payment_gateway,
        });
      }
    }
  }
  // check for existing NACH register loans
  createuser = (e) => {
    //this.setState({ isSpin: true, time_start: moment() });
    e.preventDefault();
    this.setState({
      isRegisterdisable: true,
    });
    console.log("create user", this.state.prevloans);
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    let cnt = 0;

    if (this.state.prevloans.length > 0) {
      this.state.prevloans.forEach((element) => {
        let prevcollectionac = "";
        // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
        const params = {
          id: element.map_id,
        };
        const queryParams = new URLSearchParams(params).toString();
        customFetch(
          process.env.REACT_APP_URL + "/get/mapped/bank/details?" + queryParams,
          {
            method: "GET",
            headers: { "api-token": api_token },
          }
        )
          .then((res) => {
            if (res.headers.get("api_code") > 0) {
              this.setState({
                isErrOpen: true,
                api_error_message: res.headers.get("api_error_message"),
                isSpin: false,
              });
            }
            return res.json();
          })
          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
            prevcollectionac = json[0].bank_account_number;
            console.log("prevcollectionac", prevcollectionac);
            let nach_token_id = "";
            console.log("hhaccount_number", element.account_number);
            console.log("selhhaccount_number", this.state.acno);
            if (
              prevcollectionac === this.state.collection_ac.split("~")[0] &&
              element.account_number === this.state.acno
            ) {
              console.log("nach_token_id", element.nach_token_id);
              nach_token_id = element.nach_token_id;
              //nach_type = element.nach_type;
              this.setState({
                source_loan_id: element.prev_loan_documentation_id,
              });
              console.log("nach_token_id" + cnt, nach_token_id);
              if (nach_token_id) {
                this.setState({
                  iSduplicateNACH: true,
                  isCreateuser: false,
                });
                return;
              } else {
                cnt++;
              }
            } else {
              cnt++;
            }
            console.log(
              "this.state.prevloans.length",
              this.state.prevloans.length
            );
            console.log("cnt", cnt);
            if (this.state.prevloans.length === cnt) {
              console.log("Create new ");
              this.createnewuser();
            }
          })
          .catch((error) => console.log("error", error));
      });
    } else {
      this.createnewuser();
    }
    //if (this.state.prevloans.length === cnt) {
  };
  //Duplocate NACH request
  duplicate_nach() {
    this.setState({ isSpin: true, iSduplicateNACH: false });
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    formdata.append("source_loan_doc_id", this.state.source_loan_id);
    formdata.append("target_loan_doc_id", this.state.loan_documentation_id);
    formdata.append("user_id", uid);
    customFetch(process.env.REACT_APP_URL + "/set/duplicate/nach", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isOrderid: true,
          orderSuccess: json.message,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  // NACH Register
  createnewuser() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({ isSpin: true, time_start: moment() });
    const formdata = new FormData();
    if (this.state.nachtyp !== nachtyp_status.Physical) {
      formdata.append("nach_type", nachtyp_status.Expired);
      formdata.append("auth_type", this.state.nachtyp);
    } else {
      formdata.append("nach_type", nachtyp_status.Physical);
    }
    formdata.append("bank_acc_no", this.state.acno.trim());
    formdata.append("ifsc_code", this.state.ifsc.trim());
    formdata.append("loan_id", this.state.loan_id);
    formdata.append(
      "beneficiary_name",
      this.state.name_as_per_account.toLowerCase().trim()
    );
    formdata.append(
      "acc_type",
      this.state.account_type_name.toLowerCase().trim()
    );
    formdata.append("map_id", this.state.collection_ac.split("~")[1]);
    customFetch(process.env.REACT_APP_URL + "/set/nach/details", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //     isSpin: false,
        //   });
        // }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (this.state.nachtyp === nachtyp_status.Physical) {
          if (json.order_id) {
            this.setState({
              PhisicalSuccess: "Your Orderid: " + json.order_id,
              isPhisical: true,
              isOrderidnull: false,
              isCreateuser: false,
              isSpin: false,
              iSduplicateNACH: false,
            });
          } else {
            this.setState({
              PhisicalSuccess: json.message,
              isPhisical: true,
              isOrderidnull: false,
              isCreateuser: false,
              isSpin: false,
              iSduplicateNACH: false,
            });
          }
        } else {
          if (json.order_id) {
            this.setState({
              orderSuccess: "Your Invoice: " + json.order_id,
              isOrderid: true,
              isOrderidnull: false,
              isCreateuser: false,
              isSpin: false,
              iSduplicateNACH: false,
            });
          } else {
            this.setState({
              orderSuccess: json.message,
              isOrderid: true,
              isOrderidnull: false,
              isCreateuser: false,
              isSpin: false,
              iSduplicateNACH: false,
            });
          }
        }
        this.setState({
          nachtyp: "",
          acno: "",
          ifsc: "",
          loan_id: "",
          name_as_per_account: "",
          account_type_name: "",
        });
      })
      .catch((error) => console.log("error", error));
  }

  inactivenachcases() {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    customFetch(process.env.REACT_APP_URL + "/get/inactivenachcases?household_number="+this.state.search_Deactivatedtext, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log(json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          inactivenachcases: json,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  searchCustomer(event) {
    this.state.sort_data = [];

    this.state.loan_list = this.state.og_data;

    for (let i = 0; i < this.state.loan_list.length; i++) {
      if (
        this.state.loan_list[i].household_number &&
        this.state.loan_list[i].household_number
          .toLowerCase()
          .indexOf(this.state.search_text.toLowerCase()) >= 0
      ) {
        this.state.sort_data.push(this.state.loan_list[i]);
        // console.log();
      }
    }

    this.state.loan_list = this.state.sort_data;

    console.log(this.state.loan_list.length);
    if (this.state.loan_list.length == 0) {
      this.setState({
        isErrOpen: true,
        api_error_message: "No Data Found!",
      });
    }
    this.forceUpdate();
  }
  updata() {
    if (this.state.isActive === 1) {
      this.fetchLoanList();
    }
    if (this.state.isActive === 2) {
      this.inactivenachcases();
    }
  }

  openNachList(hh_no) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();

    this.props.history.push({
      // pathname: process.env.REACT_APP_NACH_HOUSEHOLD_LIST,
      state: { api_token: api_token },
    });

    console.log(api_token);

    window.location.replace(
      "/NACH_Household_list?api_token=" + api_token + "&lead_id=" + hh_no
    );

    // customFetch(process.env.REACT_APP_URL + "/login", {
    //   method: "POST",
    //   body: formdata2,
    // })
    //   .then((res2) => {
    //     // if (res2.headers.get("api_code") > 0) {
    //     //   this.setState({
    //     //     isErrOpen: true,
    //     //     api_error_message: res2.headers.get("api_error_message"),
    //     //     isSpin: false,
    //     //   });
    //     // }
    //     return res2.json();
    //   })
    //   .then((data2) => {

    //     console.log(data2);
    //     if (data2.logged_in === true) {
    //       console.log(data2.api_token);

    //       window.location.replace(process.env.REACT_APP_NACH_HOUSEHOLD_LIST + "/NACH_Household_list?api_token=" + data2.api_token + "&lead_id=" + hh_no);
    //     }
    //   })
  }

  render() {
    const {
      api_error_message,
      isErrOpen,
      isUpdate,
      loan_list,
      isCreateuser,
      Pull_check,
      isPhisical,
      PhisicalSuccess,
      iSduplicateNACH,
      isOrderid,
      orderSuccess,
      viewFile,
      isView,
    } = this.state;
    const enabled =
      this.state.nachtyp !== "" &&
      this.state.collection_ac.split("~")[0] !== "";
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isOrderid
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isOrderid: false });
                  this.updata();
                  //window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{orderSuccess}</p>
              <p>Please Search In NACH Register</p>
            </div>
          </div>
        </div>
        <div
          className={
            iSduplicateNACH
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ iSduplicateNACH: false });
                  //window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                We Found a Token For The Selected Collection Account. Do You
                Want To Use The Same For This Loan A/C?
              </p>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.duplicate_nach()}
                >
                  Use Same
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.createnewuser()}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isPhisical
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isPhisical: false });
                  this.updata();
                  //window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{PhisicalSuccess}</p>
              <p>Please Search In NACH Register</p>
            </div>
          </div>
        </div>
        <div
          className={
            isCreateuser
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isCreateuser: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <form onChange={this.handleChange}>
                <div className="loanid-holder">
                  <span>Loan ID : {this.state.loan_id}</span>
                </div>
                <div className="loan-dataholder">
                  <div>HH No : {this.state.hhno}</div>
                  <div className="loandata-border">
                    Account No : {this.state.acno}
                  </div>
                  <div>
                    IFSC : {this.state.ifsc} ({this.state.bank_name})
                  </div>
                </div>
                <div className="selnach-holder">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <select
                          value={this.state.nachtyp}
                          className="form-control selnach"
                          name="nachtyp"
                          onChange={(e) => {
                            this.setState({ nachtyp: e.target.value });
                          }}
                        >
                          <option value="">Select NACH Type</option>
                          <option value="P">Physical NACH</option>
                          <option value="D">Debit Card NACH</option>
                          <option value="N">Net Banking NACH</option>
                        </select>
                      </div>
                    </div>

                    <div class="container">
                      <div class="row">
                        <div class="col-sm">
                          <select
                            className="form-control selpartnergateway"
                            id="exampleFormControlSelect1"
                            name="productdata"
                            //defaultValue={this.state.partner_id}
                            value={this.state.productdata}
                            //onChange={this.getpg}
                            disabled={true}
                          >
                            <option value="">Select Partner</option>
                            {this.state.partnerpayment.map(
                              (paypartner, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={paypartner.partner_id}
                                  >
                                    {paypartner.partner_name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        <div class="col-sm">
                          <select
                            className="form-control selpartnergateway"
                            id="exampleFormControlSelect1"
                            name="collection_ac"
                            value={this.state.collection_ac}
                            onChange={(e) => {
                              this.setState({ collection_ac: e.target.value });
                            }}
                          >
                            <option value="">Select Payment Gateway</option>
                            {this.state.pgdata.map((pg, index) => {
                              return (
                                <option
                                  key={index}
                                  value={
                                    pg.bank_account_number + "~" + pg.map_id
                                  }
                                >
                                  {pg.payment_vendor_name +
                                    " / " +
                                    pg.bank_account_number}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Loan Id: <strong>{this.state.loan_id}</strong>
                  </li>
                  <li className="list-group-item">
                    HH No.: <strong>{this.state.hhno}</strong>
                  </li>
                  <li className="list-group-item">
                    Account No.: <strong>{this.state.acno}</strong>
                  </li>
                  <li className="list-group-item">
                    IFSC:{" "}
                    <strong>
                      {this.state.ifsc} ({this.state.bank_name})
                    </strong>
                  </li>
                </ul> */}
                <div className="form-group regbtn-holder">
                  <button
                    className={!enabled ? "regbtn regbtnopacity" : "regbtn"}
                    type="submit"
                    disabled={!enabled}
                    // disabled={
                    //   this.state.nachtyp === "" &&
                    //   this.state.collection_ac === ""
                    // }
                    onClick={this.createuser}
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="approveRejectDailog"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Remarks
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input
                  type="text"
                  id="remarks"
                  className="form-control"
                  placeholder="Enter Remark *"
                  onChange={this.handleRemarks}
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => this.approvalRejection(this)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="deactivationDailog"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Remarks
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input
                  type="text"
                  id="remarks"
                  className="form-control"
                  placeholder="Enter Remark *"
                  onChange={this.handleRemarks}
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => this.deactivateNACH(this)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Deactivate NACH</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <Tabs
              activeTab="1"
              className=""
              ulClassName=""
              activityClassName="bg-success"
              onClick={(event, tab) => this.handleTabClick(event, tab)}
            >
              <Tab title="Deactivate NACH" className="mr-3">
                <div className="search-holder mt-3">
                  <div className="container row">
                    <div className="col-10">
                      <input
                        className="form-control"
                        placeholder="Enter Household ID"
                        onChange={(e) =>
                          this.setState({
                            search_text: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src={require("../assets/images/searchicn.png")}
                        className="searchbtn"
                        onClick={() => this.fetchLoanList()}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="loan-charge-holder">
                    {/* <div className="row">
                      <div className="col-md-4">
                        <select
                          className="form-control"
                          name="monthdata"
                          onChange={this.updatemonthdata}
                        >
                          <option>select Month</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                  {loan_list.length > 0
                    ? loan_list.map((pulldata, index) => (
                        <div className="card-data">
                          <div className="head-data">
                            <div className="row container">
                              <div className="col-4">
                                <div>Household Name:</div>
                                <div className="head-lid">
                                  {pulldata.household_name}
                                </div>
                              </div>
                              <div className="col-4">
                                <div>Household ID:</div>
                                <div className="head-lid">
                                  {pulldata.household_number}
                                </div>
                              </div>
                              {/* <div className="col-4">
                                <div className="pulamt">
                                  <span>
                                    {pulldata.next_pull_amount
                                      ? pulldata.next_pull_amount + " /-"
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="pullamt-label text-center mt-2">
                                  <span>Next Pull Amount</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="hhdetails">
                            <h2>Bank Details</h2>
                            <div class="bank-detail">
                              <div class="bankdetail-data">
                                Bank Name:
                                <span class="float-right">
                                  {pulldata.bank_name}
                                </span>
                              </div>
                              <div class="bankdetail-data border-top">
                                Bank A/C No:
                                <span class="float-right">
                                  {pulldata.bank_acc_no}
                                </span>
                              </div>
                              <div class="bankdetail-data border-top">
                                IFSC:
                                <span class="float-right">{pulldata.ifsc}</span>
                              </div>
                            </div>

                            <div className="operlabel mt-2">Operations</div>
                            <div className="row container mt-2 text-center">
                              {pulldata.deactivate_request_flag === 0 ? (
                                // <button
                                //   className="btn btn-danger"
                                //   onClick={() => this.deactivateNACH(pulldata.id)}
                                // >
                                //   Deactivate
                                // </button>
                                <div className="col-4">
                                  <button
                                    className="opebtn deact"
                                    data-toggle="modal"
                                    data-target="#deactivationDailog"
                                    onClick={() =>
                                      this.setBankIDForDeactivation(
                                        pulldata.bank_id,
                                        pulldata.household_id
                                      )
                                    }
                                  >
                                    Deactivate
                                  </button>
                                </div>
                              ) : pulldata.deactivate_request_flag === 1 ? (
                                <>
                                  <div className="col-4">
                                    <button
                                      className="opebtn aprve"
                                      data-toggle="modal"
                                      data-target="#approveRejectDailog"
                                      onClick={() =>
                                        this.approveReject(
                                          pulldata.bank_id,
                                          pulldata.household_id,
                                          1
                                        )
                                      }
                                    >
                                      Approve
                                    </button>
                                  </div>
                                  <div className="col-4">
                                    <button
                                      className="opebtn rjact"
                                      data-toggle="modal"
                                      data-target="#approveRejectDailog"
                                      onClick={() =>
                                        this.approveReject(
                                          pulldata.bank_id,
                                          pulldata.household_id,
                                          2
                                        )
                                      }
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {/* <table className="table table-hover" id="findash">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        id="chkall"
                        onChange={this.chkall}
                      />
                    </th>
                    
                    <th scope="col">Loan Id</th>
                    
                    <th scope="col">NACH Pull Amount</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Bank Account No</th>
                    <th scope="col">Operations</th>
                  </tr>
                </thead>
                <tbody>
                  {loan_list.length > 0 ? (
                    loan_list.map((pulldata, index) => (
                      <tr className="nach-pull" key={index}>
                        <td>
                          <input
                            type="checkbox"
                            id={`pull_${index}`}
                            value={pulldata.id}
                            className="sub_chk"
                            onChange={this.handleCheckboxChange}
                          />
                        </td>
                        <td>{pulldata.loan_id}</td>
                        <td>{pulldata.next_pull_amount}</td>
                        <td>{pulldata.bank_name}</td>
                        <td>{pulldata.bank_acc_no}</td>
                        <td>
                          
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-center">No Data Found</div>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Loan Id</th>
                    <th scope="col">NACH Pull Amount</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Bank Account No</th>
                    <th scope="col">Operations</th>
                  </tr>
                </tfoot>
              </table> */}
                </div>
              </Tab>
              <Tab title="Deactivated Tokens" className="mr-3">
              <div className="search-holder mt-3">
                  <div className="container row">
                    <div className="col-10">
                      <input
                        className="form-control"
                        placeholder="Enter Household ID"
                        onChange={(e) =>
                          this.setState({
                            search_Deactivatedtext: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-2">
                      <img
                        src={require("../assets/images/searchicn.png")}
                        className="searchbtn"
                        onClick={() => this.inactivenachcases()}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {this.state.inactivenachcases.length > 0
                    ? this.state.inactivenachcases.map((item, index) => (
                        <div className="card-data">
                          <div className="head-data">
                            <div className="row container">
                              <div className="col-4">
                                <div>Household Name:</div>
                                <div className="head-lid">
                                  {item.household_name}
                                </div>
                              </div>
                              <div className="col-4">
                                <div>Household ID:</div>
                                <div className="head-lid">
                                  {item.household_number}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="hhdetails">
                            <h2>Bank Details</h2>
                            <div class="bank-detail">
                              <div class="bankdetail-data">
                                Bank Name:
                                <span class="float-right">
                                  {item.bank_name}
                                </span>
                              </div>
                              <div class="bankdetail-data border-top">
                                Bank A/C No:
                                <span class="float-right">
                                  {item.account_number}
                                </span>
                              </div>
                              <div class="bankdetail-data border-top">
                                IFSC:
                                <span class="float-right">{item.ifsc}</span>
                              </div>
                            </div>
                          </div>
                          <div className="nach_inactive_ops row container mt-2">
                            <div className="left-button col-6">
                              <div className="updownimg">
                                <img
                                  src={require("../assets/images/add-fin.png")}
                                  className="add-new"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.openNachList(item.household_number);
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        // <div className="card-holder mt-3">
                        //   <div class="row">
                        //     <div className="card-name-holder col-9">
                        //       <div className="row">
                        //         <div className="col-8">
                        //           <div className="card-name">
                        //             {item.borrower}
                        //           </div>
                        //           <div className="card-hhid">
                        //             HH ID : {item.household_number}
                        //           </div>
                        //         </div>
                        //         <div className="col-4">
                        //           <span className="card-status">
                        //             <div
                        //               className={
                        //                 item.nach_status_type_flag === "I"
                        //                   ? "labelbg ibg"
                        //                   : item.nach_status_type_flag === "A"
                        //                     ? "labelbg ibg"
                        //                     : item.nach_status_type_flag === "R"
                        //                       ? "labelbg rbg"
                        //                       : item.nach_status_type_flag === "P"
                        //                         ? "labelbg pbg"
                        //                         : item.nach_status_type_flag === "U"
                        //                           ? "labelbg ubg"
                        //                           : "labelbg"
                        //               }
                        //             >
                        //               {item.nach_status_type_description}
                        //             </div>
                        //           </span>
                        //         </div>
                        //       </div>
                        //     </div>
                        //     {/* <div className="nachbranch-holder col-3">
                        //       <span>{item.branch_name}</span>
                        //     </div> */}
                        //   </div>
                        //   {/* <div className="loan-detail">
                        //     <div>
                        //       Loan ID :
                        //       <span className="float-right">
                        //         {item.loan_id}
                        //       </span>
                        //     </div>
                        //   </div> */}
                        //   <div className="bank-label">Bank Detail</div>
                        //   <div className="bank-detail">
                        //     <div className="bankdetail-data">
                        //       Bank Name :
                        //       <span className="float-right">
                        //         {item.bank_name}
                        //       </span>
                        //     </div>
                        //     <div className="bankdetail-data tb-border">
                        //       Account No :
                        //       <span className="float-right">
                        //         {item.account_number}
                        //       </span>
                        //     </div>
                        //     <div className="bankdetail-data">
                        //       Nach Type :
                        //       <span className="float-right">
                        //         {item.nach_type === "P"
                        //           ? "Physical"
                        //           : item.nach_type === "N"
                        //             ? "Net Banking"
                        //             : item.nach_type === "D"
                        //               ? "Debit Card"
                        //               : ""}
                        //       </span>
                        //     </div>
                        //   </div>
                        //   <div className="button-holder row">
                        //     <div className="left-button col-6">
                        //       <div className="updownimg">
                        //         {/* NACH Register Function call */}
                        //         <img
                        //           src={require("../assets/images/add-fin.png")}
                        //           className="add-new"
                        //           onClick={(e) => {
                        //             e.stopPropagation();

                        //             this.openNachList(item.household_number);

                        //             // this.setData(item.household_data)
                        //           }
                        //           }
                        //           // onClick={() =>
                        //           //   this.createuserpopup(
                        //           //     item.household_id,
                        //           //     item.account_number,
                        //           //     item.ifsc,
                        //           //     item.household_number,
                        //           //     item.name_as_per_account,
                        //           //     item.account_type_name,
                        //           //     item.loan_id,
                        //           //     item.bank_name,
                        //           //     item.loan_number,
                        //           //     item.partner_id
                        //           //   )
                        //           // }
                        //           alt=""
                        //         />
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                      ))
                    : ""}
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NACH_Deactivate);
