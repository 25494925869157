import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import UserExperior from "user-experior-web";
import CryptoJS from "crypto-js";

const encryptFlag = process.env.REACT_APP_ENCRYPT_FLAG == '1'
const AES_BLOCK_SIZE = 16;
const AES_ENCRYPTION_KEY_LENGTH = 32

let RSA_PUBLIC_KEY = null

async function customFetch(url, options) {
  let aesKey = null

  const ue = new UserExperior();
  if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
    ue.startRecording(Cookies.get('parameter_value'), {
      sessionReplay: { 
          maskAllInputs: true,
          maskInputOptions: {
              password: true,
              email: true,
              tel: true,
              color: false,
              date: false,
              'datetime-local': false,
              month: false,
              number: false,
              range: false,
              search: false,
              text: true,
              time: false,
              url: false,
              week: false,
              textarea: false,
              select: false,
          }
      }
    });
  
    ue.setUserIdentifier(`${localStorage.getItem("in_username")}_${process.env.REACT_APP_ENVIRONMENT}`);

  let body = JSON.stringify(options.body)
  
  let urlName = url.split('/');
    
  let eventLog = {
    'body' : body,
    'headers': JSON.stringify(options.headers),
    'method': options.method,
    'url': url
  }
  
  ue.logEvent(urlName[urlName.length - 1], eventLog);
  }
  if (encryptFlag) {
    if (!RSA_PUBLIC_KEY) {
      await loadRSAPublicKey()
    }

    aesKey = generateAESKey();
    const AESEncryptedKey = await encryptAESKeyWithRSA(aesKey);

    url = encrypt_get_params(url, aesKey);
    let contentType = options.headers ? options.headers["Content-Type"] : undefined
  
    if (!contentType && options.body) {
      if (options.body instanceof FormData) {
        contentType = 'multipart/form-data';
      } else if (typeof options.body === 'object') {
        contentType = 'application/json'
      } else if (typeof options.body === 'string') {
        if(isvalidjson(options.body)) contentType = 'application/json'
        else contentType = 'text/plain';
      }
    }
  
    if (options.body) {
      const encryptedBody = await encrypt_post_data(options.body, contentType, aesKey);
  
      if(contentType === 'application/json'){
        const data = {
          encrypted_data: encryptedBody
        }
        options.body = JSON.stringify(data)
      }
      else{
        const formData = new FormData();
        formData.append("encrypted_data", encryptedBody);
        options.body = formData
      }
  
      // if(options.headers) delete options.headers["Content-Type"]
      if(options.headers) {
        options.headers["Content-Type"] = options.headers["Content-Type"] || contentType
        if(contentType === 'multipart/form-data') delete options.headers["Content-Type"]
      }
    }

    options.headers = options.headers || {};
    options.headers["Security-Token"] = AESEncryptedKey
  }

  options.headers = options.headers || {};
  options.headers["api-token"] = localStorage.getItem("api_token");
  return await isTokenExpired(url, options, aesKey);
  //return await call_api(url, options);
} 

const loadRSAPublicKey = async () => {
  try {
    const response = await fetch('/keys/rsa_public_key.pem');
    if (!response.ok) {
      throw new Error('Failed to load RSA public key');
    }
    const rawKey = await response.text();
    RSA_PUBLIC_KEY = rawKey.replace(/-----.*-----|\s/g, '');
    console.log("RSA Public Key Loaded", RSA_PUBLIC_KEY);
  } catch (error) {
    console.error("Error loading RSA public key:", error);
  }
};

const importRSAPublicKey = async (pemKey) => {
  const publicKeyBytes = base64ToUint8Array(pemKey);
  return window.crypto.subtle.importKey(
    'spki',
    publicKeyBytes,
    { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
    true,
    ['encrypt']
  );
};

const encryptAESKeyWithRSA = async (aesKey) => {
  try {
    if (!RSA_PUBLIC_KEY) {
      await loadRSAPublicKey();
    }

    const publicKey = await importRSAPublicKey(RSA_PUBLIC_KEY);

    const encryptedKey = await window.crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      publicKey,
      aesKey
    );

    // Convert encrypted key to Base64
    const encryptedKeyBase64 = arrayBufferToHex(encryptedKey);
    return encryptedKeyBase64;
  } catch (error) {
    console.error('Error encrypting AES key:', error);
    throw error;
  }
};

const arrayBufferToHex = (buffer) => {
  const byteArray = new Uint8Array(buffer);
  return Array.from(byteArray).map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const generateAESKey = () => {
  return window.crypto.getRandomValues(new Uint8Array(AES_ENCRYPTION_KEY_LENGTH)); // 32 bytes = 256 bits
};

const encrypt_get_params = (url, aesKey) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  params.forEach((value, key) => {
    const encryptedValue = encrypt_data_aes(value, aesKey);
    params.set(key, encryptedValue);
  });

  urlObj.search = params.toString();
  return urlObj.toString();
};

const encrypt_post_data = async (bodySrc, contentType, aesKey) => {
  if (contentType.includes('application/json')) {
    try {
      const request_body_parsed = typeof bodySrc === 'string' ? JSON.parse(bodySrc) : bodySrc;
      return encrypt_data_aes(JSON.stringify(request_body_parsed), aesKey);
    } catch (error) {
      console.error("Failed to parse JSON body:", error);
      throw new Error("Invalid JSON body");
    }

  } else if (contentType.includes('multipart/form-data')) {
    const jsonObject = {};
    const fileConversionPromises = []
    const filesConverted = []
    for (const [key, value] of bodySrc.entries()) {
      if (value instanceof File) {
        fileConversionPromises.push(
          file_to_base64(value).then(base64Data => {
            const mime_type = value.type || 'application/octet-stream';
            filesConverted.push({
              content: base64Data,
              filename: value.name,
              filetype: mime_type
            })
          })
        );
      } else {
        jsonObject[key] = value;
      }
    }
    await Promise.all(fileConversionPromises);
    if(filesConverted.length > 0){
      jsonObject["file"] = filesConverted
    }

    return encrypt_data_aes(JSON.stringify(jsonObject), aesKey);
  } else if (contentType.includes('text/plain')) {
    return encrypt_data_aes(bodySrc, aesKey);
  } else if (contentType.includes('application/x-www-form-urlencoded')) {
    const params = new URLSearchParams(bodySrc);
    let jsonConvertedBody = {};
    params.forEach((value, key) => {
      jsonConvertedBody[key] = value;
    });

    return encrypt_data_aes(JSON.stringify(jsonConvertedBody), aesKey);
  }

  throw new Error("Unsupported Content-Type");
};

function encrypt_data_aes(data, aesKey) {
  const keyBytes = CryptoJS.lib.WordArray.create(aesKey)
  const iv = CryptoJS.lib.WordArray.random(AES_BLOCK_SIZE);

  const dataen = CryptoJS.AES.encrypt(data, keyBytes, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const combinedData = iv.concat(dataen.ciphertext)
  const hexEncoded = CryptoJS.enc.Hex.stringify(combinedData)
  
  return hexEncoded;
}

const file_to_base64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const isTokenExpired = async (url, options, aesKey) => {
  const token = localStorage.getItem("api_token");

  if (options.skipRefresh) {
    console.log("Token expiry check skipped.");
    return await call_api(url, options);
  }

  if (token) {
    var decoded = jwt_decode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      return await generate_token(url, options, aesKey);
    }
  }
  return await call_api(url, options)
};

const generate_token = async (url, options, aesKey) => {
  const formdata = new FormData();
  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      if(encryptFlag){
        const data = {
          refresh_token: Cookies.get("refresh_token")
        }
        const reqData = encrypt_data_aes(JSON.stringify(data), aesKey)
        formdata.append("encrypted_data", reqData);

        const AESEncryptedKey = await encryptAESKeyWithRSA(aesKey);
        options.headers = options.headers || {};
        options.headers["Security-Token"] = AESEncryptedKey
      }
      else {
        formdata.append("refresh_token", Cookies.get("refresh_token"));
      }
      try {
        const response = await fetch(process.env.REACT_APP_URL + "/get/access/token", {
          method: "POST",
          body: formdata,
          headers: options.headers
        });

        if (response.ok) {
          const data3 = await response.json();
          localStorage.setItem("api_token", data3.access_token);
          options.headers["api-token"] = data3.access_token;
          return await call_api(url, options);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log("Error fetching token:", error);
      }
    }
  } else{
    Cookies.remove("api_token");
    localStorage.clear();
    window.location.href = "/";
  }
};

const isvalidjson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const call_api = async (url, options) => {  
  const ue = new UserExperior();
  if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
    ue.startRecording(Cookies.get('parameter_value'), {
      sessionReplay: { 
          maskAllInputs: false,
          maskInputOptions: {
              password: false,
              email: false,
              tel: false,
              color: false,
              date: false,
              'datetime-local': false,
              month: false,
              number: false,
              range: false,
              search: false,
              text: false,
              time: false,
              url: false,
              week: false,
              textarea: false,
              select: false,
          }
      }
    });
  }
  console.log("call_api->", url + "==" + options.headers["api-token"]);
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      console.log("Network response was not ok:", response);
      if (response.status !== 200) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    const jsonData = await response;
    if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
      ue.logEvent('Response', {
        'headers': JSON.stringify(jsonData),
        'status': jsonData.status,
        'url': jsonData.url
      });
    }
    return jsonData;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export default customFetch;
