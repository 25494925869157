import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { logout, chkapiinput } from "./Utility";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      PassChange: false,
      passw: "",
      isErrOpen: false,
      SuccessOpen: false,
      api_error_message: "",
      isViewpass: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlemailSubmit = this.handlemailSubmit.bind(this);
  }

  handleAdrenalinRedirect = async () => {
    const api_token = localStorage.getItem("api_token");
    const in_username = localStorage.getItem("in_username");
    const user_id = localStorage.getItem("in_userid");
  
    if (!api_token || !in_username || !user_id) {
      return;
    }
  
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_URL}/get_adrenalinesso_endpoint?user_id=${in_username}`,
        {
          method: "GET",
          headers: {
            "api-token": api_token,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch the SSO endpoint.");
      }
  
      const data = await response.json();
  
      if (data.SSO_KEY) {        
        if (process.env.REACT_APP_ENVIRONMENT == "prod") {
          const adrenalinUrl = `https://peoplesync.myadrenalin.com/AdrenalinMax/#/?SSO=${data.SSO_KEY}&Company=NAVADHAN`;
          //window.location.href = adrenalinUrl;
          let a = document.createElement('a');
          a.target = '_blank';
          a.href = adrenalinUrl;
          a.click();
        }
      } else {
        throw new Error("SSO_KEY not found in the response.");
      }
    } catch (error) {
      console.error("Error fetching the SSO endpoint:", error);
    }
  };  
  
  viewpass() {
    this.setState({ isViewpass: !this.state.isViewpass });
  }
  componentDidMount() {
    document.title = "Profile";
    //AT-1659 | AT-1767 | Ankur Makavana | 05-07-2023 | store ui performance data
    // setTimeout(() => {
    //   if (document.readyState === "complete") {
    //     this.context.onPageLoad();
    //   } else {
    //     window.addEventListener("load", this.context.onPageLoad());
    //     this.cleanup = () =>
    //       window.removeEventListener("load", this.context.onPageLoad());
    //   }
    // }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
  }
  handlemailSubmit(event) {
    event.preventDefault();
    this.setState({ PassChange: false });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    /*Check if data is null then logout*/
    var arr = [uid, this.state.passw];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function () {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("userid", uid);
    formdata.append("user_password", this.state.passw);
    customFetch(process.env.REACT_APP_URL + "/changepassword", {
      method: "POST",
      headers: { "api-token": api_token, payload_id: this.context.payloadId },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (json.isupdate === true) {
          this.setState({ SuccessOpen: true });
        }
      })
      .catch((error) => console.log("error", error));
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const uname = localStorage.getItem("employee_name");
    const phone_no = localStorage.getItem("phone_no");
    const {
      PassChange,
      isErrOpen,
      SuccessOpen,
      api_error_message,
      isViewpass,
    } = this.state;
    return (
      <>
        <div
          className={
            SuccessOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ SuccessOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Password Updated Succesfully!</p>
            </div>
          </div>
        </div>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            PassChange
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ PassChange: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="resetpass">
                <form onSubmit={this.handlemailSubmit}>
                  <input
                    {...(isViewpass === true
                      ? { type: "password" }
                      : { type: "text" })}
                    name="passw"
                    placeholder="Password"
                    className="form-control email-box"
                    value={this.state.passw}
                    onChange={this.handleChange}
                  />
                  <img
                    src={require("../assets/images/view.png")}
                    className="resetview-pass"
                    onClick={() => this.viewpass()}
                  />
                  <div className="text-center">
                    <input
                      type="submit"
                      className="btn btn-lg btn-primary email-submit"
                      disabled={!this.state.passw}
                      value="Reset Password"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Profile</div>
              <div className="log-out">
                <img
                  src={require("../assets/images/question.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="card card-border mt-3">
                <div className="card-body">
                  <div className="profile-image-holder col-md-2 float-left">
                    <img
                      src={require("../assets/images/user (5).png")}
                      alt=""
                    />
                  </div>
                  <div className="profile-name-holder col-md-6 float-left">
                    <span className="profile-name">{uname}</span>
                    <br />
                    <span className="profile-contact">{phone_no}</span>
                  </div>
                </div>
              </div>
              <div className="seclabel">Security</div>
              <div className="card card-border mt-3">
                <div className="card-body">
                  <div className="profile-notif-holder col-md-2 float-left">
                    <img
                      src={require("../assets/images/rotation-lock.png")}
                      alt=""
                    />
                  </div>
                  <div className="profile-notif-holder col-md-6 float-left">
                    <span className="profile-name">Push Notification</span>
                    <br />
                    <span className="profile-contact">
                      Enable or Disable Notification
                    </span>
                  </div>
                  <div className="profile-name-holder col-md-4 float-left">
                    <div className="radio_input pt-3">
                      <div className="switch_box box_1 move-end">
                        <input
                          type="checkbox"
                          className="switch_1"
                          name="incorrect_number"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card card-border mt-3"
                onClick={this.handleAdrenalinRedirect}
                style={{ cursor: "pointer" }}
              >
                <div className="card-body">
                  <div className="profile-adrenalin-holder col-md-2 float-left">
                    <img
                      src={require("../assets/images/adrenalin.png")}
                      alt="Go To Adrenalin Icon"
                    />
                  </div>
                  <div className="profile-notif-holder col-md-6 float-left d-flex align-items-center">
                    <div>
                      <span className="profile-name">Go To Adrenalin</span>
                      <br />
                      <span className="profile-contact">Access Adrenalin Portal</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card card-border mt-3">
                <div
                  className="card-body cursor-pointer"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/change-password",
                    })
                  }}
                >
                  <div className="profile-resetpass-holder col-md-2 float-left">
                    <img src={require("../assets/images/key.png")} alt="" />
                  </div>
                  <div className="profile-resetpass-holder col-md-6 float-left">
                    <span className="profile-name">Change Password</span>
                    <br />
                    <span className="profile-contact">
                      Reset your app password
                    </span>
                  </div>
                  <div className="profile-name-holder col-md-4 float-left">
                    <div className="move-end pt-3">
                      <img src={require("../assets/images/next.png")} alt="" />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card card-border mt-3 mb-3">
                <div
                  className="card-body cursor-pointer"
                  onClick={() => logout()}
                >
                  <div className="profile-logout-holder col-md-2 float-left">
                    <img
                      src={require("../assets/images/power-off-black.png")}
                      alt=""
                    />
                  </div>
                  <div className="profile-logout-holder col-md-6 float-left pt-3">
                    <span className="profile-logout-name">Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Profile);
